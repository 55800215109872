import { defineStore } from 'pinia'
import { TermItemDefinition } from '@/services/term'

export const useSearchContactStore = defineStore('searchContactStore', {
  state: () => ({
    Contact_name1: null as string | null,
    Contact_name2: null as string | null,
    Contact_zip: null as string | null,
    Contact_locality: null as string | null,
    Contact_phone: null as string | null,
    Contact_addition: null as string | null,
    Contact_address1: null as string | null,
    Contact_email: null as string | null,
    Contact_languageId: null as number | null,
    Contact_country: null as string | null,
    useTerms: true,
    items: [
      { key: 'Contact_name1', operation: '~' },
      { key: 'Contact_name2', operation: '~' },
      { key: 'Contact_zip', operation: '~' },
      { key: 'Contact_locality', operation: '~' },
      { key: 'Contact_phone', operation: '~' },
      { key: 'Contact_addition', operation: '~' },
      { key: 'Contact_address1', operation: '~' },
      { key: 'Contact_email', operation: '~' },
      { key: 'Contact_languageId', operation: ':' },
      { key: 'Contact_country', operation: ':' }
    ] as TermItemDefinition[]
  })
})
